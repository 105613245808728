import { render, staticRenderFns } from "./buy-step.vue?vue&type=template&id=331c91c9&scoped=true"
import script from "./buy-step.vue?vue&type=script&lang=js"
export * from "./buy-step.vue?vue&type=script&lang=js"
import style0 from "./buy-step.vue?vue&type=style&index=0&id=331c91c9&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "331c91c9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('331c91c9')) {
      api.createRecord('331c91c9', component.options)
    } else {
      api.reload('331c91c9', component.options)
    }
    module.hot.accept("./buy-step.vue?vue&type=template&id=331c91c9&scoped=true", function () {
      api.rerender('331c91c9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shop/mobileQueryApp/goBuyActivate/components/buy-step.vue"
export default component.exports