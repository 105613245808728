<template>
  <!-- 线上订单 -->
  <div class="wrap" id="wrap">
    <div class="navSearch x-b" id="search-card">
      <!-- 搜索区域 -->
      <div class="left-search">
        <!-- 高级搜索按钮 -->
        <div>
          <div class="x-f">
            <div class="x-f marR10">
              <div class="fS14MR10">订单日期</div>
              <el-date-picker unlink-panels size="mini" class="marR10" v-model="datetime" type="datetimerange"
                start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
              <el-radio-group class="marR10" v-model="filterTime" size="mini">
                <el-radio-button label="today">今天</el-radio-button>
                <el-radio-button label="yesterday">昨天</el-radio-button>
                <el-radio-button label="latelyThreeAndDay">近三天</el-radio-button>
              </el-radio-group>
              <selectAllFilter class="marR20" v-model="queryParams" :filters="dropDownList" />
              <el-button type="primary" size="mini" @click="getList">查询</el-button>
              <el-button icon="el-icon-download" size="mini" @click="handleExport">导出
              </el-button>
              <!-- <el-button
                icon="el-icon-refresh"
                size="mini"
                @click="reset($event)"
                >重置</el-button
              > -->
            </div>
          </div>
          <div class="x-f marT10">
            <div class="x-f marR10">
              <div class="fS14MR10">取货日期</div>
              <el-date-picker unlink-panels size="mini" class="marR10" v-model="pickUpDatetime" type="datetimerange"
                start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
              <el-radio-group v-model="pickUpFilterTime" size="mini">
                <el-radio-button label="today">今天</el-radio-button>
                <el-radio-button label="tomorrow">明天</el-radio-button>
                <el-radio-button label="nextThreeAndDay">近三天</el-radio-button>
              </el-radio-group>
            </div>
            <div class="x-f marR10">
              <span class="fS14MR10">类型</span>
              <SelectLocal :multiple="true" v-model="queryParams.orderTypes" style="width: 180px" :option="{
                  data: dict.type.order_type,
                  value: 'value',
                  label: 'label'
                }" />
            </div>
            <div class="x-f marR10">
              <span class="fS14MR10">取货方式</span>
              <SelectLocal :multiple="true" v-model="queryParams.fetchTypes" style="width: 180px" :option="{
                  data: dict.type.fetch_type,
                  value: 'value',
                  label: 'label'
                }" />
            </div>
          </div>
          <div class="x-f marT10">
            <div class="x-f marR10">
              <span class="fS14MR10">订单状态</span>
              <SelectLocal :multiple="true" v-model="queryParams.orderStatuss" style="width: 180px" :option="{
                  data: orderStatusList,
                  value: 'value',
                  label: 'label'
                }" />
            </div>
            <div class="x-f marR10">
              <span class="fS14MR10">生产部门</span>
              <SelectLocal :multiple="true" v-model="queryParams.produceDeptIds" style="width: 180px" :option="
                  $select({
                    key: 'listDepartment'
                  }).option
                " />
            </div>
            <div class="x-f marR10">
              <span class="fS14MR10">预定门店</span>
              <SelectRemote :multiple="true" v-model="queryParams.orderShopIds" style="width: 180px" :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                " />
            </div>
            <div class="x-f">
              <span class="fS14MR10">取货门店</span>
              <SelectRemote :multiple="true" v-model="queryParams.shopIds" style="width: 180px" :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                " />
            </div>
          </div>
        </div>
      </div>
      <!-- 控制区域 -->
      <div class="right-control marR10">
        <div class="marB10">
          <span class="marR10">自动通知生产</span>
          <el-switch v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_74" @change="
              setSaveShopOrderTipConfig(
                'produce',
                shopOrderTip.shop_ORDER_CONFIG_TIP_74
              )
            "></el-switch>
        </div>
        <div class="marB10">
          <span class="marR10">自动接单</span>
          <el-switch v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_71" @change="
              setSaveShopOrderTipConfig(
                'orderTake',
                shopOrderTip.shop_ORDER_CONFIG_TIP_71
              )
            "></el-switch>
        </div>
        <div class="marB10">
          <span class="marR10">自动接单并打印</span>
          <el-switch v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_75" @change="
              setSaveShopOrderTipConfig(
                'orderTakePrint',
                shopOrderTip.shop_ORDER_CONFIG_TIP_75
              )
            "></el-switch>
        </div>
        <!-- <div class="marB10">
          <span class="marR10">有新订单时语音提示</span>
          <el-switch
            v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_72"
            @change="
              setSaveShopOrderTipConfig(
                'voice',
                shopOrderTip.shop_ORDER_CONFIG_TIP_72
              )
            "
          ></el-switch>
        </div>
        <div class="marB10">
          有新订单时消息提示
          <el-switch
            v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_73"
            @change="
              setSaveShopOrderTipConfig(
                'message',
                shopOrderTip.shop_ORDER_CONFIG_TIP_73
              )
            "
          ></el-switch>
        </div> -->
      </div>
    </div>
    <div class="content">
      <!-- 订单详情 -->
      <div class="orderDetail">
        <div class="orderReceipt">
          <div class="title">订单详情</div>
          <div class="orderContent">
            <!-- 订单信息 -->

            <el-scrollbar :style="{ height: tableHeight + 'px' }">
              <div class="orderInfo borderB">
                <div class="row">
                  <div class="label">
                    订单编号:{{ this.reserveOrderInfo.billNo }}
                  </div>
                </div>
                <div class="row">
                  <div class="label">
                    下单时间:{{ this.reserveOrderInfo.billDate }}
                  </div>
                </div>
                <div class="row">
                  <div class="label">
                    取货门店:{{ this.reserveOrderInfo.fetchShopName }}
                  </div>
                </div>
                <div class="row">
                  <div class="label">
                    送货时间:{{ this.reserveOrderInfo.reachDate }}
                  </div>
                </div>
                <div class="row">
                  <div class="label">
                    取货方式:{{ this.reserveOrderInfo.fetchTypeName }}
                  </div>
                  <div class="val"></div>
                </div>
                <div class="row">
                  <div class="label">
                    备注:{{ this.reserveOrderInfo.remark }}
                  </div>
                  <div class="val"></div>
                </div>
              </div>
              <!-- 商品信息 -->
              <div class="goodsInfo">
                <div class="goodsTitle-list">
                  <div class="goodsTitle">编号</div>
                  <div class="goodsTitle">名称</div>
                  <div class="goodsTitle">数量</div>
                  <div class="goodsTitle">单价</div>
                  <div class="goodsTitle">小计</div>
                </div>

                <div class="goodsList">
                  <div class="goodsItem" v-for="(
                      item, index
                    ) in reserveOrderInfo.middleGroundBillOrderGoodsInfoResps" :key="index">
                    <div>{{ item.goodsNo }}</div>
                    <div class="goodsItemName">{{ item.goodsName }}</div>
                    <div>{{ item.unitQty }}</div>
                    <div>{{ item.unitPrice }}</div>
                    <div>{{ item.subtotal }}</div>
                  </div>

                  <div class="goodsTotal">
                    共{{ reserveOrderInfo.orderQty || 0 }}件商品
                  </div>
                </div>
              </div>

              <!-- 付款信息 -->
              <div class="payInfo borderB">
                <div class="row">
                  <div class="label width105">原价金额:</div>
                  <div class="val">
                    {{ this.reserveOrderInfo.billOldMoney }}
                  </div>
                </div>
                <div class="row">
                  <div class="label width105">优惠金额:</div>
                  <div class="val">
                    {{ this.reserveOrderInfo.agioMoneySum }}
                  </div>
                </div>
                <div class="row">
                  <div class="label width105">配送费用:</div>
                  <div class="val">
                    {{ this.reserveOrderInfo.deliveryFee }}
                  </div>
                </div>
                <div class="row">
                  <div class="label width105">应实付金额:</div>
                  <div class="val">{{ reserveOrderInfo.billFactMoney }}</div>
                </div>
                <div class="row">
                  <div class="label width105">已收金额:</div>
                  <div class="val">{{ reserveOrderInfo.payMoney }}</div>
                </div>
                <div class="row">
                  <div class="label width105">未收金额:</div>
                  <div class="val">{{ reserveOrderInfo.notReceivedMoney }}</div>
                </div>
              </div>
              <!-- 付款详情 -->
              <div class="payDetail borderB">
                <div>付款明细</div>
                <!-- 付款详情是动态 -->
                <div class="row" v-for="(
                    item, index
                  ) in reserveOrderInfo.billOrderListReservePayDetailsResps" :key="index">
                  <div class="label">{{ item.payModeName }}</div>
                  :
                  <div class="val">{{ item.payMoney }}</div>
                </div>
              </div>
              <!-- 客户信息 -->
              <div class="clientInfo">
                <div class="row">
                  <div class="label">
                    姓名:{{ this.reserveOrderInfo.receiverName }}
                  </div>
                </div>
                <div class="row">
                  <div class="label">
                    电话:{{ this.reserveOrderInfo.receiverPhone }}
                  </div>
                </div>
                <div class="row">
                  <div style="text-align: justify">
                    地址:{{ this.reserveOrderInfo.receiverAddress }}
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
      <!-- 订单列表 -->
      <div class="orderList">
        <div class="x-bc">
          <div class="x-f">
            <el-button size="mini" type="primary" @click="getControls(1)" :disabled="
                this.takeDeliveryOrder.orderStatus != 4 ||
                this.takeDeliveryOrder.orderInnerStatus != 0 ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">接单</el-button>
            <el-button size="mini" type="primary" @click="getControls(10)" :disabled="
                this.takeDeliveryOrder.orderStatus != 4 ||
                this.takeDeliveryOrder.orderInnerStatus != 0 ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">拒接</el-button>
            <el-button size="mini" type="primary" @click="getControls(2)" :disabled="
                !takeDeliveryOrder.billId ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">订单打印</el-button>
            <el-button size="mini" type="primary" @click="getControls(3)" :disabled="
                !(
                  this.takeDeliveryOrder.orderStatus == 4 &&
                  [2, 4].includes(this.takeDeliveryOrder.orderInnerStatus) &&
                  !loadingTable
                ) ||
                disabled == '通知生产' ||
                !this.takeDeliveryOrder.isFetchType
              ">通知生产</el-button>
            <el-button  v-if="
                this.takeDeliveryOrder &&
                this.takeDeliveryOrder.shopNo == '0000'
              "  size="mini" type="primary" @click="getControls(4)" :disabled="
                !(this.takeDeliveryOrder.orderStatus == 4 &&
                (this.takeDeliveryOrder.orderInnerStatus>=2) &&
                (this.takeDeliveryOrder.orderInnerStatus < 15) &&
                !loadingTable &&
                fullAmount &&
                this.takeDeliveryOrder.isFetchType
              )">发货</el-button>
            <el-button  v-else  size="mini" type="primary" @click="getControls(4)" :disabled="
                !(this.takeDeliveryOrder.orderStatus == 4 &&
                (this.takeDeliveryOrder.orderInnerStatus>=2) &&
                (this.takeDeliveryOrder.orderInnerStatus < 11) &&
                !loadingTable &&
                fullAmount &&
                this.takeDeliveryOrder.fetchType != 1&&
                this.takeDeliveryOrder.isFetchType
              )">发货</el-button>
            <!-- <el-button v-else size="mini" type="primary" @click="getControls(4)" :disabled="
                this.takeDeliveryOrder.orderStatus != 4 ||
                !(2 <= this.takeDeliveryOrder.orderInnerStatus) ||
                !(this.takeDeliveryOrder.orderInnerStatus < 11) ||
                loadingTable ||
                !fullAmount ||
                this.takeDeliveryOrder.fetchType == 1 ||
                !this.takeDeliveryOrder.isFetchType
              ">发货</el-button> -->
            <el-button size="mini" type="primary" @click="getControls(5)" :disabled="
                !(
                  this.takeDeliveryOrder.orderStatus == 4 &&
                  this.takeDeliveryOrder.orderInnerStatus >= 2 &&
                  this.takeDeliveryOrder.fetchType == 2 &&
                  !loadingTable &&
                  this.takeDeliveryOrder.isFetchType
                )
              ">打印配送单</el-button>
            <!-- <el-button size="mini" type="primary" @click="getControls(6)" :disabled="
                this.takeDeliveryOrder.orderStatus != 4 ||
                this.takeDeliveryOrder.orderInnerStatus < 2 ||
                this.takeDeliveryOrder.deliveryStatus != -1 ||
                this.takeDeliveryOrder.deliveryStatus != 0 ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">呼叫配送</el-button> -->
            <el-dropdown trigger="click" split-button type="primary" size="mini" @click="getControls(6)"
              @command="getControls(15)" class="marL10 marR10" :disabled='(((this.takeDeliveryOrder.orderStatus != 4 &&
                  this.takeDeliveryOrder.orderStatus != 5) ||
                this.takeDeliveryOrder.orderInnerStatus < 2 ||
                (this.takeDeliveryOrder.deliveryStatus != -1 &&
                  this.takeDeliveryOrder.deliveryStatus != 0 &&
                  this.takeDeliveryOrder.deliveryStatus != 100) ||
                (this.takeDeliveryOrder.fetchType != 2 &&
                  this.takeDeliveryOrder.fetchType != 3))&&((this.takeDeliveryOrder.orderStatus != 4 &&
                  this.takeDeliveryOrder.orderStatus != 5) ||
                this.takeDeliveryOrder.orderInnerStatus < 2 ||
                (this.takeDeliveryOrder.deliveryStatus != 1 &&
                  this.takeDeliveryOrder.deliveryStatus != 2) ||
                (this.takeDeliveryOrder.fetchType != 2 &&
                  this.takeDeliveryOrder.fetchType != 3))||!this.takeDeliveryOrder.isFetchType)'>
              呼叫配送
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="取消配送">取消配送</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button size="mini" type="primary" @click="getControls(7)" :disabled="
                !(
                  this.takeDeliveryOrder.orderStatus == 4 &&
                  [0, 2].includes(this.takeDeliveryOrder.orderInnerStatus) &&
                  this.takeDeliveryOrder.orderType == '5'
                ) ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">取消预订</el-button>
            <el-button size="mini" type="primary" @click="getControls(8)" :disabled="
                this.takeDeliveryOrder.refundStatus != 1 ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">确认退款</el-button> -->
            <el-button size="mini" type="primary" @click="getControls(8)" :disabled="
                (this.takeDeliveryOrder.refundStatus != 1 ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType) &&(!(
                  this.takeDeliveryOrder.orderStatus == 4 &&
                  [0, 2, 3, 4].includes(this.takeDeliveryOrder.orderInnerStatus) &&
                  this.takeDeliveryOrder.orderType == '5'
                ) ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType)
              ">确认退款</el-button>
            <el-button size="mini" type="primary" @click="getControls(9)" :disabled="
                this.takeDeliveryOrder.refundStatus != 1 ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">拒接退款</el-button>
            <el-button size="mini" type="primary" @click="getControls(11)" :disabled="
                !(
                  this.takeDeliveryOrder.orderStatus == 4 &&
                  [0, 2, 3, 4].includes(this.takeDeliveryOrder.orderInnerStatus)
                ) ||
                loadingTable ||
                !this.takeDeliveryOrder.isFetchType
              ">修改预订单</el-button>
          </div>
          <div>
            <span class="marR10">发货仓库</span>
            <SelectRemote v-model="outStoreId" style="width: 200px" @selectChange="changeStoreId" :option="
                $select({
                  key: 'listStore',
                  option: {
                    option: {
                      clearable: true
                    }
                  }
                }).option
              " />
          </div>
        </div>
        <!-- 订单表格 -->
        <el-table class="marT10" :data="tableData" border :max-height="tableHeight" :height="tableHeight" show-summary
          :summary-method="getSummaries" @row-click="handleRowClick" v-loading="loadingTable" element-loading-text="操作中"
          ref="multipleTable">
          <el-table-column width="55" align="center">
            <template v-slot="scope">
              <el-radio v-model="takeDeliveryOrder" :label="scope.row">{{
                ''
              }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column :show-overflow-tooltip="item.type !== 'html'" :prop="item.prop" :label="item.title" :width="item.width"
            v-for="item in tableFields" :key="item.title" align="center" >
            <template v-slot="scope">
              <el-popover placement="top" :disabled="!scope.row[item.prop]" trigger="hover" v-if="item.type === 'html'">
                <div class="set-popper" v-html="scope.row[item.prop]"></div>
                <div slot="reference" class="set-content" v-html="scope.row[item.prop]"></div>
              </el-popover>
              <div v-else>
                {{scope.row[item.prop]}}
              </div> 
            </template>
          </el-table-column>
        </el-table>
        <!-- 表格分页 -->
        <bottomPage v-model="queryParams" :getList="getList" :isSelectAll="false" :totalCom="total" />
      </div>
    </div>
    <orderPrint ref="print" :orderInfo="orderInfo" :shopId='shopId' />
    <c-dialog title="选择打印机" :width="400" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form ref="printForm" :model="printForm" :rules="printRules" label-width="90px">
          <el-form-item prop="printerName" label="打印机">
            <div class="x-f">
              <el-select size="mini" v-model="printForm.printerName" placeholder="请选择" clearable filterable
                class="marR10">
                <el-option v-for="item in printerList" :key="item.value" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
              <el-button @click="getAcquirePrint('bt')" size="mini" icon="el-icon-refresh-right">刷新</el-button>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handlePrint">确 定</el-button>
      </template>
    </c-dialog>
    <reserveDialog :openDialog.sync="openReserveDialog" :orderData="editReserveOrderInfo.billOrderReq"
      @billOrderReqCom="billOrderReqCom" />
    <!--呼叫弹窗 -->
    <CallDelivery :openDialog.sync="openCallDeliveryDialog" :billId="takeDeliveryOrder.billId" :shopId="shopId"
      @getControls="getControls" :fetchType="takeDeliveryOrder.fetchType" :reserveOrderInfo="reserveOrderInfoCom" />
    <!--取消呼叫 -->
    <CancDelivery :openDialog.sync="openCancelDialog" :billId="takeDeliveryOrder.billId" @getControls="getControls" />
  </div>
</template>

<script>
import CallDelivery from './components/callDelivery'
import CancDelivery from './components/cancDelivery'
import reserveDialog from './components/reserveDialog' //修改预订单弹窗
import { listPaymode } from '@/api/shop/base/paymode.js' //付款方式
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import {
  updateShopBillOrder, //修改订单
  orderMiddleListAPI, //门店订单中台列表
  orderMiddleListSummaryAPI,
  orderMiddleInfoAPI, //门店订单中台详情
  orderStateAPI,
  shopOrderTipConfig,
  getShopBillOrderInfo,
  saveShopOrderTipConfig, //门店订单中台状态
  saveShopBillPosRetail, //新增门店零售单
  getShopBillPosNo, //取货预定编号
  shopBillPosOutAndIn, //生成调出单调入单
  getTenantOutStore, //获取该商户对应的订单中台调出仓库
  setTenantOutStore, //存储该商户对应的订单中台调出仓库
  cancelShopBillOrder, //取消预定
  notifyProduction, //通知生产
  getAssembleShopInfo
} from '@/api/shop/orderCenter/orderManage' //门店中台
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import bottomPage from '@/views/components/bottomPage' //底部分页
import orderPrint from './components/orderPrint'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { deepCopy, getTimes } from '@/utils/index'
import selectAllFilter from '@/components/tablePage/select/select-all-filter/index.vue' //搜索
import { fcount, isObject, isNumber } from '@/utils'
import { timestampToTime } from "@/utils/index.js";
import {
  getFormattedDate //获取时间
} from '@/utils'
export default {
  name: 'OrderManage',
  dicts: ['order_type', 'fetch_type'],
  components: {
    SelectLocal,
    bottomPage,
    orderPrint,
    CDialog,
    SelectRemote,
    selectAllFilter,
    reserveDialog,
    CancDelivery,
    CallDelivery
  },
  data() {
    return {
      reserveOrderInfoCom: {}, //预订单详情
      openCancelDialog: false, //取消呼叫弹窗
      openCallDeliveryDialog: false, //呼叫弹窗
      disabled: '',
      shopId: null,
      openReserveDialog: false,
      //修改预订单据详情
      editReserveOrderInfo: {
        billOrderReq: {
          fetchType: 1, //取货方式
          fetchRemindHour: 1, //提前几小时
          imageUrls: [],
        },
      },
      timer: null,
      summary: ['billFactMoney', 'payMoney', 'notReceivedMoney', 'orderQty'],
      summaryDateils: {},
      datetime: undefined,
      filterTime: undefined,
      pickUpDatetime: undefined,
      pickUpFilterTime: undefined,
      outStoreId: undefined, //调出仓库
      //预订单是否足额
      fullAmount: true,
      //标记下标
      controlIndex: 2,
      //打印机
      printerList: [],
      //打印机表单
      printForm: {},
      //校验表单
      printRules: {
        printerName: [
          {
            required: true,
            message: '打印机不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      //打印机
      dialogFormVisible: false,
      orderStatusList: [
        {
          label: '全部',
          value: '1'
        },
        {
          label: '未接单',
          value: '2'
        },
        {
          label: '待发货',
          value: '3'
        },
        {
          label: '待提货',
          value: '4'
        },
        {
          label: '已取货',
          value: '5'
        },
        {
          label: '已完成',
          value: '6'
        },
        {
          label: '待退款',
          value: '10'
        },
        {
          label: '已退货',
          value: '7'
        },
        {
          label: '已拒单',
          value: '9'
        }
      ], //状态数组
      //订单打印信息
      orderInfo: {},
      //单选
      takeDeliveryOrder: {},
      //详情
      reserveOrderInfo: {},
      tableData: [], //订单中台表格列表
      //表格列字段
      tableFields: [
        { prop: 'billNo', title: '订单编号', width: '180' },
        { prop: 'billDate', title: '订单时间', width: '160' },
        { prop: 'orderStatusName', title: '订单状态', width: '100' },
        { prop: 'orderInnerStatusName', title: '完成进度', width: '100' },
        { prop: 'refundStatusName', title: '退款状态', width: '100' },
        { prop: 'orderTypeName', title: '订单类型', width: '100' },
        { prop: 'deliveryName', title: '配送状态', width: '100' },
        { prop: 'path', title: '轨迹', width: '100',type:'html' },
        { prop: 'orderPrintCount', title: '打印次数', width: '100' },
        { prop: 'billFactMoney', title: '应实付金额', width: '100' },
        { prop: 'payMoney', title: '已收金额', width: '100' },
        { prop: 'notReceivedMoney', title: '未收金额', width: '100' },
        { prop: 'orderQty', title: '数量', width: '100' },
        { prop: 'orderShopName', title: '预定门店', width: '160' },
        { prop: 'produceDeptName', title: '生产部门', width: '160' },
        { prop: 'fetchTypeName', title: '取货方式', width: '120' },
        { prop: 'fetchShopName', title: '取货门店', width: '160' },
        { prop: 'reachDate', title: '取货时间', width: '200' },
        { prop: 'receiverName', title: '姓名', width: '120' },
        { prop: 'receiverPhone', title: '电话', width: '120' },
        { prop: 'receiverAddress', title: '地址', width: '160' },
        { prop: 'remark', title: '备注', width: '' }
      ],
      total: 0, // 总条数
      // 表格遮罩
      loadingTable: false,
      // 查询参数
      queryParams: {
        orderStatuss: ['1'],
        manageType: 1,
        pageNum: 1,
        pageSize: 15
      },
      //搜索框搜索的条件
      dropDownList: [
        { filter: 'query', label: '全部' },
        { filter: 'billNos', label: '订单编号' },
        { filter: 'receiverNames', label: '姓名' },
        { filter: 'receiverPhones', label: '电话' },
        { filter: 'receiverAddresss', label: '地址' },
        { filter: 'remarks', label: '备注' }
      ],
      shopOrderTip: {
        shop_ORDER_CONFIG_TIP_74: false,
        shop_ORDER_CONFIG_TIP_71: false,
        shop_ORDER_CONFIG_TIP_72: false,
        shop_ORDER_CONFIG_TIP_73: false
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
      isDatetime: true
    }
  },
  watch: {
    //单据日期
    datetime(newVal, oldVal) {
      if (newVal) {
        this.queryParams.beginBillDate = newVal[0]
        this.queryParams.endBillDate = newVal[1]
        const dates = ['today', 'yesterday', 'latelyThreeAndDay']
        const arr = dates.map(type =>
          getTimes({
            type,
            format: 'datetimerange'
          })
        )
        const arrIndex = arr.findIndex(
          ([o, t] = []) => newVal[0] === o && newVal[1] === t
        )
        if (arrIndex >= 0) {
          this.filterTime = dates[arrIndex]
        } else {
          this.filterTime = undefined
        }
      } else {
        this.queryParams.beginBillDate = undefined
        this.queryParams.endBillDate = undefined
        this.filterTime = undefined
      }
    },
    //取货日期
    pickUpDatetime(newVal, oldVal) {
      if (newVal) {
        this.queryParams.beginPickUpDate = newVal[0]
        this.queryParams.endPickUpDate = newVal[1]
        const dates = ['today', 'tomorrow', 'nextThreeAndDay']
        const arr = dates.map(type =>
          getTimes({
            type,
            format: 'datetimerange'
          })
        )
        const arrIndex = arr.findIndex(
          ([o, t] = []) => newVal[0] === o && newVal[1] === t
        )
        if (arrIndex >= 0) {
          this.pickUpFilterTime = dates[arrIndex]
        } else {
          this.pickUpFilterTime = undefined
        }
      } else {
        this.queryParams.beginPickUpDate = undefined
        this.queryParams.endPickUpDate = undefined
        this.pickUpFilterTime = undefined
      }
    },
    //单据日期天数
    filterTime: {
      handler(newVal, oldVal) {
        if (newVal) {
          try {
            let [one, two] = getTimes({
              type: newVal,
              format: 'datetimerange'
            })
            if (
              one !== this.queryParams.beginBillDate ||
              two !== this.queryParams.endBillDate
            ) {
              this.datetime = [one, two]
            }
          } catch (error) { }
        }
      }
    },
    //取货日期天数
    pickUpFilterTime: {
      handler(newVal, oldVal) {
        if (newVal) {
          try {
            let [one, two] = getTimes({
              type: newVal,
              format: 'datetimerange'
            })
            if (
              one !== this.queryParams.beginPickUpDate ||
              two !== this.queryParams.endPickUpDate
            ) {
              this.pickUpDatetime = [one, two]
            }
          } catch (error) { }
        }
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 80
    }
  },
 async mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
      const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
      await awaitTime(500);
      await this.$nextTick();
      this.getAcquirePrint()
  },
  beforeDestroy() {
    this.clerHandAuto()
    window.removeEventListener('resize', this.handleResize)
  },
  async created() {
    const res = await getAssembleShopInfo()
    this.shopId = res?.data?.shopId
    await this.reset()
    //自动
    await this.getShopOrderTipConfig()
    await this.handAutoBtn()
    //仓库
    const res1 = await getTenantOutStore()
    this.outStoreId = res1?.data || undefined
  },
  methods: {
    async billOrderReqCom(value) {
      if (value) {
        this.editReserveOrderInfo.billOrderReq = {
          ...value,
          fetchShopId: value.fetchShopId,
          reachDate: value.reachDate,
          fetchTime: value.fetchTime,
        };
        this.editReserveOrderInfo.billOrderListReserveDetailReqs=null;
        this.editReserveOrderInfo.billOrderListReservePayDetailReqs=null;
        try {
          await updateShopBillOrder(this.editReserveOrderInfo)
          this.$message.success('修改成功')
          this.getList()
        } catch (err) {
          this.$message.error(err.message)
        }
      }
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/api/system/shop/base/orderMiddleGround/getOrderMiddleFroundListExport',
        {
          ...this.queryParams
        },
        `订单管理导出${getTimeDate()}.xlsx`
      )
    },
    datetimeChange(newVal, aaa = false) {
      if (newVal) {
        this.queryParams.beginBillDate = newVal[0]
        this.queryParams.endBillDate = newVal[1]
        const dates = ['today', 'yesterday', 'latelyThreeAndDay']
        const arr = dates.map(type =>
          getTimes({
            type,
            format: 'datetimerange'
          })
        )
        const arrIndex = arr.findIndex(
          ([o, t] = []) => newVal[0] === o && newVal[1] === t
        )
        if (arrIndex >= 0) {
          this.filterTime = dates[arrIndex]
        } else {
          this.filterTime = undefined
        }
      } else {
        this.queryParams.beginBillDate = undefined
        this.queryParams.endBillDate = undefined
        this.filterTime = undefined
      }
      if (aaa) {
        return true
      }
    },
    pickUpDatetimeChange(newVal, aaa = false) {
      if (newVal) {
        this.queryParams.beginPickUpDate = newVal[0]
        this.queryParams.endPickUpDate = newVal[1]
        const dates = ['today', 'tomorrow', 'nextThreeAndDay']
        const arr = dates.map(type =>
          getTimes({
            type,
            format: 'datetimerange'
          })
        )
        const arrIndex = arr.findIndex(
          ([o, t] = []) => newVal[0] === o && newVal[1] === t
        )
        if (arrIndex >= 0) {
          this.pickUpFilterTime = dates[arrIndex]
        } else {
          this.pickUpFilterTime = undefined
        }
      } else {
        this.queryParams.beginPickUpDate = undefined
        this.queryParams.endPickUpDate = undefined
        this.pickUpFilterTime = undefined
      }
      if (aaa) {
        return true
      }
    },
    filterTimeChange(newVal) {
      if (newVal) {
        try {
          let [one, two] = getTimes({
            type: newVal,
            format: 'datetimerange'
          })
          if (
            one !== this.queryParams.beginBillDate ||
            two !== this.queryParams.endBillDate
          ) {
            this.datetime = [one, two]
          }
        } catch (error) { }
      }
    },
    pickUpFilterTimeChange(newVal) {
      if (newVal) {
        try {
          let [one, two] = getTimes({
            type: newVal,
            format: 'datetimerange'
          })
          if (
            one !== this.queryParams.beginPickUpDate ||
            two !== this.queryParams.endPickUpDate
          ) {
            this.pickUpDatetime = [one, two]
          }
        } catch (error) { }
      }
    },
    //重置
    async reset(e) {
      if (e) {
        let target = e?.target
        if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
          target = e.target.parentNode
        }
        target.blur()
      }
      //清空查询参数
      this.queryParams = {
        orderStatuss: ['1'],
        manageType: 1,
        pageNum: 1,
        pageSize: 15
      }
      this.filterTime = 'latelyThreeAndDay' //天数
      this.pickUpFilterTime = 'nextThreeAndDay' //取货日期天数
      await this.$nextTick()
      await this.getList()
    },
    async changeStoreId(val) {
      if (val) {
        await setTenantOutStore({ outStoreId: val })
        this.$message.success('切换发货仓库成功')
      }
    },
    clerHandAuto() {
      try {
        clearInterval(this.timer)
        this.timer = null
      } catch (error) { }
    },
    //自动按钮
    async handAutoBtn() {
      this.clerHandAuto()
      this.timer = setInterval(async () => {
        let curDate = getFormattedDate(2, "-"); //当前日期

        if (
          this.shopOrderTip.shop_ORDER_CONFIG_TIP_81 ||
          this.shopOrderTip.shop_ORDER_CONFIG_TIP_85
        ) {
          //自动接单
          let misseList = await this.missedOrder({
            manageType: 1, //管理类型(1总部订单管理2门店订单管理)
            orderStatus: "2",
            beginBillDate: this.shopOrderTip.shop_ORDER_CONFIG_TIP_81 || this.shopOrderTip.shop_ORDER_CONFIG_TIP_85,
            endBillDate: curDate + " 23:59:59",
            pageNum: 1,
            pageSize: 999999999999999,
            shopId: this.shopId
          })
          for (let i = 0; i < misseList.length; i++) {
            const item = misseList[i];
            // 接单状态
            await orderStateAPI({
              billId: item.billId,
              state: '1'
            })
            console.log('已改完状态')
            if (this.shopOrderTip.shop_ORDER_CONFIG_TIP_85) {
              console.log('准备打印1')
              let localStorageName = localStorage.getItem(
                'orderCenterPrintName'
              )
              console.log('准备打印2', localStorageName)
              if (localStorageName) {
                await this.getOrderDetails(item.billId)
                console.log('准备打印3', localStorageName)
                //订单打印
                this.orderInfoPrint =
                  await this.$refs.print.getorderPrintTemplate(localStorageName)
                //修改打印次数
                await orderStateAPI({
                  billId: item.billId,
                  state: '4'
                })
              }
            }
          }
        }
        if (this.shopOrderTip.shop_ORDER_CONFIG_TIP_84) {
          //自动通知生产
          const noticeList = await this.missedOrder({
            manageType: 1, //管理类型(1总部订单管理2门店订单管理)
            orderStatus: "11",
            beginBillDate: this.shopOrderTip.shop_ORDER_CONFIG_TIP_84,
            endBillDate: curDate + " 23:59:59",
            pageNum: 1,
            pageSize: 999999999999999,
            shopId: this.shopId
          });
          console.log('准备通知生产', noticeList)
          noticeList.forEach(async item => {
            await this.callProduction(item)
          })
        }
        await this.$refs.print.handlePrintReceiptCook()
        await this.getList()
      }, 15 * 1000)
    },
    //确定打印
    handlePrint() {
      this.$refs['printForm'].validate(async valid => {
        if (valid) {
          // 存储数据
          localStorage.setItem(
            'orderCenterPrintName',
            this.printForm.printerName
          )
          this.cancel()
          //订单信息
          await this.getOrderDetails()
          if (this.controlIndex == 2) {
            try {
              //订单打印
              await this.$refs.print.getorderPrintTemplate(
                localStorage.getItem('orderCenterPrintName')
              )
              //修改打印次数
              await orderStateAPI({
                billId: this.takeDeliveryOrder.billId,
                state: '4'
              })
              this.getList()
            } catch (error) { }
          } else if (this.controlIndex == 5) {
            //配送打印
            await this.$refs.print.getDeliveryTemplate(
              localStorage.getItem('orderCenterPrintName')
            )
          }
          console.log('printNa', localStorage.getItem('orderCenterPrintName'))
          this.loadingTable = false
        }
      })
    },
    //关闭弹窗
    cancel() {
      this.dialogFormVisible = false
      this.resetForm('printForm')
    },
    async getAcquirePrint(val) {
      this.printerList = []
      const hiprintTemplate2 = new hiprint.PrintTemplate()
      const counterList = hiprintTemplate2.getPrinterList()
      if (counterList) {
        this.printerList = counterList.map((item, index) => {
          return {
            name: item.name,
            value: index
          }
        })
      }
      // 获取数据
      this.$set(
        this.printForm,
        'printerName',
        localStorage.getItem('orderCenterPrintName')
      )
      if (val == 'bt') {
        this.$message.success('刷新成功')
      }
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    },
    async getControls(val) {
      this.loadingTable = true
      switch (val) {
        case 1:
          // 接单状态
          await orderStateAPI({
            billId: this.takeDeliveryOrder.billId,
            state: '1'
          })
          await this.getList()
          this.$message({
            showClose: true,
            message: '接单成功',
            type: 'success'
          })
          break;
        case 2:
          // 获取数据
          this.$set(
            this.printForm,
            'printerName',
            localStorage.getItem('orderCenterPrintName')
          )
          //标记打印的按钮
          this.controlIndex = val
          //打开打印
          this.dialogFormVisible = true
          this.loadingTable = false
          return
          break;
        case 3:
          await this.callProduction()
          break;
        case 4:
          if (this.reserveOrderInfo.shopNo != '0000' && !this.outStoreId) {
            this.loadingTable = false
            return this.$message.error('发货仓库不能为空')
          }
          try {
            if (this.reserveOrderInfo.fetchShopNo == '0000') {
              // 提货状态
              await orderStateAPI({
                billId: this.takeDeliveryOrder.billId,
                state: '2'
              })
              let res = await getShopBillOrderInfo(this.takeDeliveryOrder.billId)
              let orderInfo = res.data
              let payMoneyOld =
                orderInfo.billOrderListReservePayDetailReqs.reduce(
                  (money, item) => {
                    money += item.payMoney
                    return money
                  },
                  0
                )
              orderInfo.sourceOrderSubType = orderInfo?.billOrderReq?.orderSubType
              orderInfo.sourceBillId = orderInfo.billId
              //当已收定金已经足额 直接完成零售单
              if (orderInfo.billMoney == payMoneyOld) {
                //过滤组合头
                orderInfo.shopBillPosRetailDetailReq =
                  orderInfo.billOrderListReserveDetailReqs.filter(item => {
                    if (Number(item.goodsId) < 0) return false //过滤组合头
                    //可退数量
                    item.returnableQuantity = item.unitQty
                    item.purPrice = item.unitPurPrice
                    item.salePrice = item.unitSalePrice
                    return true
                  })
                let modeArr = await listPaymode({ payModeId: 12 })
                const { data } = await getShopBillPosNo()
                orderInfo.billNo = data
                orderInfo.billPosNo = orderInfo.billNo
                //拼装数据
                orderInfo = {
                  ...orderInfo,
                  shopBillPosRetailPayDetailReq: [
                    {
                      payModeId: 12,
                      payModeName: '已收定金',
                      payMoney: payMoneyOld,
                      isRevenue: modeArr[0]?.isRevenue || true //是否营收
                    }
                  ],
                  billOrderListReserveDetailReqs: undefined,
                  billOrderListReservePayDetailReqs: undefined,
                  billOrderReq: undefined,
                  isRetailOrder: true //取货
                }
                orderInfo.retailType = 0
                orderInfo.billType = 140300 //单据类型
                //预定转为零售
                await saveShopBillPosRetail(orderInfo)

                await this.getList()
                this.$message.success('发货成功')
              }
            } else{
              // 提货状态
              await orderStateAPI({
                billId: this.takeDeliveryOrder.billId,
                state: '2'
              })
              await shopBillPosOutAndIn({
                billId: this.reserveOrderInfo.billId,
                outStoreId: this.outStoreId
              })
              await this.getList()
              this.$message.success('发货成功')
            }
          } catch (error) {
            this.loadingTable = false
            this.$message.error('发货失败')
          }
          break;
        case 5:
          // 获取数据
          this.$set(
            this.printForm,
            'printerName',
            localStorage.getItem('orderCenterPrintName')
          )
          //标记打印的按钮
          this.controlIndex = val
          //打开打印
          this.dialogFormVisible = true
          this.loadingTable = false
          break;
        case 6:
          // 呼叫配送
          {
            try {
              if (
                (this.takeDeliveryOrder.orderStatus != 4 &&
                  this.takeDeliveryOrder.orderStatus != 5) ||
                this.takeDeliveryOrder.orderInnerStatus < 2 ||
                (this.takeDeliveryOrder.deliveryStatus != -1 &&
                  this.takeDeliveryOrder.deliveryStatus != 0 &&
                  this.takeDeliveryOrder.deliveryStatus != 100) ||
                (this.takeDeliveryOrder.fetchType != 2 &&
                  this.takeDeliveryOrder.fetchType != 3)
              ) {
                return this.$message.error("当前订单状态无法呼叫配送！");
              }
              this.reserveOrderInfoCom = deepCopy(this.reserveOrderInfo)
              this.openCallDeliveryDialog = true;
            } finally {
              this.loadingTable = false;
            }
          }
          break;
        case 7:
          this.$confirm('确定取消预订此单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              //退款
              const { data } = await cancelShopBillOrder(
                this.takeDeliveryOrder.billId
              )
              let billDate = getFormattedDate(4, '-')
              let billDateStr = getFormattedDate(2, '-')
              let billTimeStr = getFormattedDate(5)
              this.orderInfo = {
                ...data,
                billDate,
                billDateStr,
                billTimeStr,
                orderTyprTitle: '取消预订小票'
              }
              await this.getList()
              this.$message({
                showClose: true,
                message: '取消预订成功',
                type: 'success'
              })
              let localStorageName = localStorage.getItem('orderCenterPrintName')
              if (!localStorageName)
                return this.$message.error('取消预订打印失败，请先选择打印机')
              await this.$refs.print.getorderPrintTemplate(localStorageName)
            })
            .catch(() => {
              this.loadingTable = false
            })
          break;
        case 8:
          {
            try {
              if (!(this.takeDeliveryOrder.refundStatus != 1 ||
                !this.takeDeliveryOrder.isFetchType)) {
                await this.handleRefund()
              } else if (!(!(
                this.takeDeliveryOrder.orderStatus == 4 &&
                [0, 2, 3, 4].includes(this.takeDeliveryOrder.orderInnerStatus) &&
                this.takeDeliveryOrder.orderType == '5'
              ) ||
                !this.takeDeliveryOrder.isFetchType)) {
                await this.handleCancellation()
              } else {
                this.$message.error("当前订单状态无法确认退款！");
              }
            } catch (err) {
              this.$message.error(err.message || err.msg || "退款失败");
            } finally {
              this.loadingTable = false;
            }
          }
          // this.$confirm('确定退款此单?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // })
          //   .then(async () => {
          //     // 接单状态
          //     await orderStateAPI({
          //       billId: this.takeDeliveryOrder.billId,
          //       state: '16'
          //     })
          //     this.getList()
          //     this.$message({
          //       showClose: true,
          //       message: '退款成功',
          //       type: 'success'
          //     })
          //   })
          //   .catch(() => { })
          break;
        case 9:
          this.$confirm('确定拒接退款此单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              // 接单状态
              await orderStateAPI({
                billId: this.takeDeliveryOrder.billId,
                state: '17'
              })
              this.getList()
              this.$message({
                showClose: true,
                message: '拒绝退款成功',
                type: 'success'
              })
            })
            .catch(() => { })
          break;
        case 10:
          //拒接
          this.$confirm('确定拒接此单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              //接单状态
              await orderStateAPI({
                billId: this.takeDeliveryOrder.billId,
                state: '15'
              })
              this.getList()
              this.$message({
                showClose: true,
                message: '拒接成功',
                type: 'success'
              })
            })
            .catch(() => { })
          break;
        case 11:
          {
            try {
              let { data } = await getShopBillOrderInfo(
                this.takeDeliveryOrder.billId
              )
              //赋值取货日期 取货时间段
              if (!data?.billOrderReq?.reachDate) {
                data.billOrderReq.fetchShopId = data.billOrderReq.fetchShopId;
                data.billOrderReq.reachDate = data.billOrderReq.reachDate;
                data.billOrderReq.fetchTime = data.billOrderReq.fetchTime;
              }
              this.editReserveOrderInfo = data
              this.openReserveDialog = true
            } finally {
              this.loadingTable = false
            }
          }
          break;
        case 14: //呼叫配送刷新状态
          {
            try {
              this.getList();
              this.$message.success("呼叫配送成功");
            } finally {
              this.loadingTable = false;
            }
          }
          break;
        case 15: //取消配送
          {
            try {
              if (
                (this.takeDeliveryOrder.orderStatus != 4 &&
                  this.takeDeliveryOrder.orderStatus != 5) ||
                this.takeDeliveryOrder.orderInnerStatus < 2 ||
                (this.takeDeliveryOrder.deliveryStatus != 1 &&
                  this.takeDeliveryOrder.deliveryStatus != 2) ||
                (this.takeDeliveryOrder.fetchType != 2 &&
                  this.takeDeliveryOrder.fetchType != 3)
              ) {
                return this.$message.error("当前订单状态无法取消配送！");
              }
              this.openCancelDialog = true;
            } finally {
              this.loadingTable = false;
            }
          }
          break;
        case 16: //取消配送刷新状态
          {
            try {
              this.getList();
              this.$message.success("取消配送成功");
            } finally {
              this.loadingTable = false;
            }
          }
          break;
        default:
          break;
      }

    },
    //确认退款
    async handleRefund() {
      if (!(await this.$confirm("您已经跟生产部确认退款了吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }))) {
        // 用户点击了取消，不执行退款
        return;
      }
      if (!(await this.$confirm("再次确认退款该订单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }))) {
        // 用户点击了取消，不执行退款
        return;
      }
      await orderStateAPI({
        billId: this.takeDeliveryOrder.billId,
        state: "16",
        shopId: this.shopId,
      });
      this.loadingTable = false;
      this.$message.success("确定退款成功！");
      this.getList();
    },
    //取消预订
    async handleCancellation() {
      if (!(await this.$confirm("您已经跟生产部确认退款了吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }))) {
        // 用户点击了取消，不执行退款
        return;
      }
      if (!(await this.$confirm("再次确认退款该订单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }))) {
        // 用户点击了取消，不执行退款
        return;
      }
      const { data } = await cancelShopBillOrder(
        this.takeDeliveryOrder.billId
      )
      let billDate = getFormattedDate(4, '-')
      let billDateStr = getFormattedDate(2, '-')
      let billTimeStr = getFormattedDate(5)
      this.orderInfo = {
        ...data,
        billDate,
        billDateStr,
        billTimeStr,
        orderTyprTitle: '取消预订小票'
      }
      await this.getList()
      this.$message({
        showClose: true,
        message: '确定退款成功！',
        type: 'success'
      })
      let localStorageName = localStorage.getItem('orderCenterPrintName')
      if (!localStorageName)
        return this.$message.error('取消预订打印失败，请先选择打印机')
      await this.$refs.print.getorderPrintTemplate(localStorageName)
    },
    //订单详情
    async getOrderDetails(billId) {
      let res1 = await getShopBillOrderInfo(
        billId || this.takeDeliveryOrder.billId
      )
      this.orderInfo = res1.data
      this.orderInfo.amountReceived = 0
      this.orderInfo.billOrderListReservePayDetailReqs.forEach(item => {
        this.orderInfo.amountReceived += item.payMoney
      })
      this.orderInfo.outstandingAmount =
        this.orderInfo.billMoney - this.orderInfo.amountReceived
      this.orderInfo.orderTyprTitle =
        this.orderInfo?.billOrderReq?.orderType == 5 ? '预订小票' : '线上订单'
    },
    //点击选中
    async handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.loadingTable = true
      this.takeDeliveryOrder = row
      const res = await orderMiddleInfoAPI(row.billId)
      //预定单据详情
      this.reserveOrderInfo = res.data
      if (this.reserveOrderInfo?.orderType == 5) {
        let payMoneyOld =
          this.reserveOrderInfo?.billOrderListReservePayDetailsResps?.reduce(
            (money, item) => {
              money += item.payMoney
              return money
            },
            0
          )
        //判断是否足额
        this.fullAmount = this.reserveOrderInfo?.billMoney == payMoneyOld
      } else {
        //判断是否足额
        this.fullAmount = true
      }

      //行数据的
      this.loadingTable = false
    },
    //通知生产
    async callProduction(info) {
      try {
        const takeDeliveryOrder = info || this.takeDeliveryOrder
        if (this.loadingTable) this.loadingTable = false
        this.loadingTable = true
        const remark = takeDeliveryOrder.orderInnerStatus == 2 ? "厨打" : "重打";
        // 通知生产修改状态
        await orderStateAPI({
          billId: takeDeliveryOrder.billId,
          state: '18'
        })
        this.disabled = "通知生产";
        //推送通知生产
        await notifyProduction({
          billId: takeDeliveryOrder.billId,
          shopId: this.shopId,
          remark
        })
        this.disabled = "";
        await this.getList()
        this.$message({
          showClose: true,
          message: '通知生产成功',
          type: 'success'
        })
        console.log('通知生产成功')
      } finally {
        this.loadingTable = false
      }
    },
    //按钮详情
    async getShopOrderTipConfig() {
      const res = await shopOrderTipConfig()
      this.shopOrderTip = res.data
    },
    //添加按钮
    async setSaveShopOrderTipConfig(name, value) {
      // 根据不同的name设置对应的属性
      switch (name) {
        case 'produce':
          this.shopOrderTip.shop_ORDER_CONFIG_TIP_84 = this.setValueOrUnset(value);
          break;
        case 'orderTake':
          this.shopOrderTip.shop_ORDER_CONFIG_TIP_81 = this.setValueOrUnset(value);
          if (value) {
            this.shopOrderTip.shop_ORDER_CONFIG_TIP_75 = false;
            this.shopOrderTip.shop_ORDER_CONFIG_TIP_85 = undefined;
          }
          break;
        case 'orderTakePrint':
          this.shopOrderTip.shop_ORDER_CONFIG_TIP_85 = this.setValueOrUnset(value);
          if (value) {
            this.shopOrderTip.shop_ORDER_CONFIG_TIP_71 = false;
            this.shopOrderTip.shop_ORDER_CONFIG_TIP_81 = undefined;
          }
          break;
        case 'voice':
          this.shopOrderTip.shop_ORDER_CONFIG_TIP_82 = this.setValueOrUnset(value);
          break;
        case 'message':
          this.shopOrderTip.shop_ORDER_CONFIG_TIP_83 = this.setValueOrUnset(value);
          break;
        default:
          // 如果没有匹配的name，可以选择抛出错误或什么也不做
          break;
      }
      // 保存配置
      await saveShopOrderTipConfig(this.shopOrderTip);
      // 调用其他函数
      this.handAutoBtn();
    },
    // 封装设置值或取消设置的逻辑
    setValueOrUnset(value) {
      return value ? getTimeDate() : undefined;
    },
    // 查询表格列表
    async getList(bol = true) {
      if (this.loadingTable) this.loadingTable = false
      this.loadingTable = true
      const res = await orderMiddleListAPI(this.queryParams)
      const res1 = await orderMiddleListSummaryAPI(this.queryParams)
      this.summaryDateils = res1.data || {}
      res.rows.forEach(row =>{
        if(row.pathItemList){
          let path = '';
          row.pathItemList.forEach((item,index)=>{
            path+=`<p>${index}、[${timestampToTime(item.actionTime)}]-${item.actionMsg}</p>`
          })
          row.path = path;
        }
        //row.path='<p>哈哈哈哈哈哈</p>'
      });
      this.tableData = res.rows
      if (this.tableData.length > 0) {
        if (!this.takeDeliveryOrder.billId) {
          this.takeDeliveryOrder = this.tableData[0];
          const res = await orderMiddleInfoAPI(this.takeDeliveryOrder.billId);
          //预定单据详情
          this.reserveOrderInfo = res.data;
          this.orderInfo = res.data;
        } else {
          const row = this.tableData.find(
            (item) => item.billId == this.takeDeliveryOrder.billId
          );
          this.handleRowClick(row || this.tableData[0]);
        }
      } else {
        this.takeDeliveryOrder = {};
        this.reserveOrderInfo = {};
      }
      this.total = res.total
      this.loadingTable = false
    },
    getSummaries({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (
          sumItem ||
          (this.summaryDateils != null &&
            Object.keys(this.summaryDateils).length > 0)
        ) {
          if (
            this.summaryDateils != null &&
            Object.keys(this.summaryDateils).length > 0
          ) {
            const arr = column?.property?.split('.')
            if (arr?.length == 1) {
              sums[index] = this.summaryDateils[column.property] || ''
            }
          } else {
            const arr = column?.property?.split('.')
            let values = data?.map(x => Number(x[column.property] || 0))
            if (arr?.length === 4) {
              const [p, i, key, s] = arr
              values = data.map(
                x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
              )
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    async missedOrder(queryParams) {
      const res = await orderMiddleListAPI(queryParams)
      return res.rows || []
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);

  .navSearch {
    // height: 110px;
    margin-bottom: 6px;
    background-color: #fff;
    // display: flex;
    // align-items: center;
    padding: 10px 0 10px 10px;
    border-radius: 4px;
    //搜索区域
    .left-search {
    }
    .right-control {
      height: 100%;
      text-align: right;
    }
  }

  .content {
    // height: calc(100% - 117px);
    border-radius: 4px;
    display: flex;

    //订单详情
    .orderDetail {
      flex: 1;
      // width: 25%;
      // height: 100%;
      background-color: #fff;
      padding: 5px 10px;
      margin-right: 6px;
      //订单小票
      .orderReceipt {
        width: 100%;
        height: 100%;
        padding: 0 3px;
        background: url(~@/assets/fixedImages/receipt-bg.png);
        background-size: 100% 100%;
        //订单头部标题
        .title {
          height: 50px;
          border-bottom: 1px dashed #e5e5e5;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }

        //订单内容
        .orderContent {
          height: 83%;
          //滚动条
          .el-scrollbar {
            height: 100%;
            overflow: hidden;

            ::v-deep .el-scrollbar__wrap {
              overflow-x: hidden;
            }

            //订单信息/付款信息/客户信息
            .orderInfo,
            .payInfo,
            .payDetail,
            .clientInfo {
              padding: 5px 10px;

              &.borderB {
                border-bottom: 1px solid #c1c1c1;
              }

              .row {
                line-height: 25px;
                display: flex;

                .label {
                  text-align: justify;
                  text-align-last: justify;

                  &.width77 {
                    width: 77px;
                  }

                  &.width105 {
                    width: 105px;
                  }

                  &.width40 {
                    width: 40px;
                  }
                }

                .val {
                  margin-left: 5px;
                }
              }
            }

            //商品信息
            .goodsInfo {
              // 商品标题
              .goodsTitle-list {
                display: flex;
                text-align: center;

                padding-bottom: 5px;

                .goodsTitle {
                  width: 25%;
                  font-weight: bold;
                  line-height: 35px;
                }
              }

              //商品详情列表
              .goodsList {
                border-bottom: 1px solid #c1c1c1;
                text-align: center;

                .goodsItem {
                  display: flex;

                  div {
                    width: 25%;
                    text-align: center;
                    flex-wrap: nowrap;
                  }

                  goodsItemName {
                    width: 100%;
                  }
                }
              }

              .goodsTotal {
                text-align: right;
                line-height: 35px;
                padding-right: 10px;
              }
            }
          }
        }

        //底部按钮
        .footer-btn {
          height: 9%;
          padding: 0 10px;
          border-top: 1px dashed #e5e5e5;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }
    }

    //订单列表
    .orderList {
      background-color: #fff;
      width: 75%;
      height: 100%;
      padding: 10px;
    }
  }
}
//滚动条
.el-scrollbar {
  height: 100%;
  overflow: hidden;
  // 侧边滚动条显示
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  ::v-deep .el-scrollbar__bar.is-vertical {
    opacity: 1;
  }
}
.content {
  height: calc(100% - 60px);
  .el-form {
    padding: 20px;
    margin: 0 auto;
  }
  //配送模式
  .tabs {
    .tab {
      width: 30%;
      height: 5%;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      margin-right: 2%;
      &.active {
        border: 1px solid #2e95ff;
        background-color: #d5eaff;
        color: #409eff;
      }
    }
  }

  //时间
  ::v-deep .el-range-editor.el-input__inner {
    width: 100%;
  }
  //名称/电话/备注
  .name,
  .phone,
  .remark {
    width: 100%;
  }
  ::v-deep .el-select,
  .el-input,
  .el-cascader {
    width: 200px !important;
  }
  ::v-deep .el-input-number {
    width: 150px !important;
  }
}
.set-popper {
  width: 331px;
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
}
.set-content {
  cursor: default;
  height: 40px;
  overflow-y: auto;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
