<template>
  <div v-show="false">
    <VueQr ref="vueQrs" :text="billId" :size="400" :callback="handleDone" />
    <div ref="divHtml">
      <img :src="imageUrl" style="width:100px;height:100px"/>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';
export default {
  components: {
    VueQr
  },
  data() {
    return {
      billId: '123',
      imageUrl: "",
    };
  },
  methods: {
    handleDone(img) {
      this.imageUrl = img;
    },
    // 生成核销二维码
    async generateBarcode(billId) {
      console.log('base64',billId);
      if (!billId) return {};
      // 获取用于显示二维码的元素
      this.billId = billId + '';
      this.$forceUpdate()
      const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
      await awaitTime(100);
      await this.$nextTick();
      console.log('base64a', this.imageUrl);
      console.log('base64b', this.$refs.divHtml);
      return {imageURL:this.imageUrl,imageHtml:this.$refs.divHtml.innerHTML};
    },
  },
};
</script>
